import Head from "next/head";
import Footer from "pages/components/Footer";
import Topbar from "pages/components/Topbar";
import React from "react";
import { ExtendedNextPage } from "types/pages";
import Content from "components/Content";
import Label from "components/Label";
import Image from "next/image";

import Mandala from "../../../public/404-mandala.png";

import styles from "./styles.module.scss";

const PageNotFound: ExtendedNextPage = () => {
  return (
    <div className={styles.container}>
      <Head>
        <title>404 page</title>
        <meta name="404" content="Humming home's 404 page" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Content
        containerClassName={styles.contentWrapper}
        contentClassName={styles.content}
      >
        <Label className={styles.title} color="primary" variant="h2-centered">
          Oops, you clicked on a link that doesn&apos;t exist!
        </Label>
        <Label
          className={styles.subtitle}
          color="primary"
          variant="s2-centered"
          useMarkdown
          linkColor="accent"
        >
          Click [here](/) to go back to our homepage
        </Label>
        <div className={styles.image}>
          <Image src={Mandala} />
        </div>
      </Content>
    </div>
  );
};

PageNotFound.getLayout = (page) => {
  return (
    <div>
      <Topbar withHeader />
      {page}
      <Footer />
    </div>
  );
};

export default PageNotFound;
